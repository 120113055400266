




















































import { defineComponent, reactive } from '@vue/composition-api'
import NbTextbox from '@/components/NbTextbox.vue'

export default defineComponent({
	name: 'InvitePage',

	components: {
		NbTextbox
	},

	props: {
		loginInfo: {}
	},

	setup(props, { root }) {
		const loginInfo = props.loginInfo as { firstName: string }

		if (loginInfo.firstName == '') {
			root.$router.replace('/login?goto=/invite')

			return
		}

		const state = reactive({
			name: '',
			email: '',
			message: ''
		})

		return { state }
	}
})
