<template>
	<div :class="'nb-textbox ' + customClass">
		<q-icon 
			class="icon" 
			:name="iconName" 
			v-if="iconName != undefined" 
			:style="inputType == 'textarea' && textareaRows > 1 ? 'align-self: flex-start; padding-top: 4px;' : ''" 
		/>

		<input 
			v-model="state.inputValue"
			class="field" 
			:type="inputType || 'text'" 
			:placeholder="inputPlaceholder" 
			v-if="inputType != 'textarea'" 
			@input="$emit('input', $event.target.value)"
		/>

		<textarea 
			v-model="state.inputValue"
			class="field" 
			:placeholder="inputPlaceholder" 
			:style="`resize: none; height: calc(26px * ${textareaRows || 1});`" 
			v-else 
			@input="$emit('input', $event.target.value)"
		/>
	</div>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
	name: 'NbTextbox',

	props: {
		value: String | undefined,
		customClass: String | undefined,
		iconName: String | undefined,
		inputType: 'text' | 'password' | undefined,
		inputPlaceholder: String | undefined,
		textareaRows: Number | undefined
	},

	setup(props) {
		const state = reactive({
			inputValue: props.value
		})

		return { state }
	}
})
</script>

<style lang="scss" scoped>
.nb-textbox {
	display: flex;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 40px;
	background-color: #fcfcfc;
	border: 1px solid rgba(#000, 0.05);
	border-right-color: rgba(#fff, 0.05);
	border-bottom-color: rgba(#fff, 0.05);
	border-radius: 4px;
	box-shadow: inset 1px 1px 8px rgba(#000, 0.05);
	padding: 8px 12px;
	overflow: hidden;

	.field {
		display: flex;
		flex: 1;
		background-color: transparent;
		font-size: 15px;
		border: none;
		color: #5761A7;
		outline: none;
		//text-transform: lowercase;
		//border: 1px solid #f00;
	}

	.field::placeholder {
		color: rgba(#5761A7, 0.5);
	}

	.icon {
		color: rgba(#5761A7, 0.7);
		font-size: 20px;
		margin-right: 8px;
	}
}
</style>